import { connect } from 'react-redux';

import { getProductById } from 'redux/modules/entities/selectors/products';

import ProductSize from './ProductSize';

export default connect((state, { productId }) => {
  const product = getProductById(state, productId) || {};

  const { currentSaleUnitPrice, defaultQuantity, formattedWeightRange, size, typicalWeight } =
    product;

  const currentSaleUnitPriceUom = currentSaleUnitPrice?.quantity?.uom;
  const defaultQuantityUom = defaultQuantity?.uom;
  const typicalWeightAmount = typicalWeight?.amount;
  const typicalWeightUom = typicalWeight?.uom;

  return {
    currentSaleUnitPriceUom,
    defaultQuantityUom,
    formattedWeightRange,
    size,
    typicalWeightAmount,
    typicalWeightUom,
  };
})(ProductSize);
