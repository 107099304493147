import { useRef, useCallback } from 'react';
import { EventType, metadataAdClick, postAdTechEvent } from 'analytics/gtmServerFunctions';
import { useSelector } from 'react-redux';
import { getProductClickData } from 'redux/modules/entities/selectors/products';
import isEqual from 'lodash/isEqual';

import { handleProductListClick } from 'utils/gtm';

export const usePodClickTracking = ({
  conflictMessage,
  isFavourite,
  position,
  productId,
  searchType,
  wrapperId,
  boosted,
  sponsored,
}) => {
  const productData = useSelector(state => getProductClickData(state, productId), isEqual);
  const { sponsorshipId, metadata, crealytics } = productData;

  const clickSentToAdTech = useRef(false);

  const reportClickToAdTech = useCallback(() => {
    if (!clickSentToAdTech.current) {
      postAdTechEvent({
        eventType: EventType.CLICK,
        sponsorshipId,
        trackingUrls: crealytics?.clickUrls,
      });
      clickSentToAdTech.current = true;
    }
  }, [sponsorshipId, crealytics?.clickUrls]);

  const reportClickToMonetate = useCallback(() => {
    if (metadata) metadataAdClick(metadata);
  }, [metadata]);

  const reportClickToDataLayer = useCallback(() => {
    handleProductListClick({
      conflictMessage,
      isFavourite,
      position,
      productData,
      searchType,
      wrapperId,
      boosted,
      sponsored,
      sponsorshipId,
    });
  }, [
    boosted,
    conflictMessage,
    isFavourite,
    position,
    productData,
    searchType,
    sponsored,
    sponsorshipId,
    wrapperId,
  ]);

  const reportAnalyticsClick = useCallback(() => {
    reportClickToDataLayer();
    reportClickToAdTech();
    reportClickToMonetate();
  }, [reportClickToAdTech, reportClickToDataLayer, reportClickToMonetate]);

  return {
    reportClickToDataLayer,
    reportClickToAdTech,
    reportClickToMonetate,
    reportAnalyticsClick,
  };
};
