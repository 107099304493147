import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ProductPrice.scss';

const ProductPrice = ({ displayPrice, formattedPriceRange, redOffer }) => {
  const price = formattedPriceRange || displayPrice;

  return (
    <span
      className={classNames({
        [styles.redText]: redOffer,
      })}
    >
      {price}
    </span>
  );
};

ProductPrice.propTypes = {
  displayPrice: PropTypes.string,
  formattedPriceRange: PropTypes.string,
  redOffer: PropTypes.bool,
};

ProductPrice.defaultProps = {
  displayPrice: '',
  formattedPriceRange: '',
  redOffer: false,
};

ProductPrice.displayName = 'ProductPrice';

export default ProductPrice;
