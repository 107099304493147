export const PRODUCT_ATTRIBUTES = [
  {
    type: 'readyToServe',
    label: 'Ready to serve',
  },
  {
    type: 'requiresHeating',
    label: 'Requires heating',
  },
  {
    type: 'requiresCooking',
    label: 'Requires cooking',
  },
  {
    type: 'serveHotOrCold',
    label: 'Serve hot or cold',
  },
  {
    type: 'ageRestriction',
    label: 'Age restriction',
  },
  {
    type: 'analgesic',
    label: 'Analgesic',
  },
  {
    type: 'colourMayVary',
    label: 'Colour may vary',
  },
  {
    type: 'essentialRange',
    label: 'essential Waitrose',
  },
  {
    type: 'fairtrade',
    label: 'Fairtrade',
  },
  {
    type: 'freezing',
    label: 'Suitable for freezing',
  },
  {
    type: 'frozen',
    label: 'Frozen',
  },
  {
    type: 'kosher',
    label: 'Kosher',
  },
  {
    type: 'microwaveable',
    label: 'Microwaveable',
  },
  {
    type: 'organic',
    label: 'Organic',
  },
  {
    type: 'previouslyFrozen',
    label: 'Previously frozen',
  },
  {
    type: 'seasonal',
    label: 'Seasonal',
  },
  {
    type: 'serviceCounter',
    label: 'Service counter',
  },
  {
    type: 'varietyMayVary',
    label: 'Variety may vary',
  },
  {
    type: 'waitroseOwnLabel',
    label: 'Waitrose own label',
  },
  {
    type: 'vegetarian',
    label: 'Vegetarian',
  },
  {
    type: 'vegan',
    label: 'Vegan',
  },
];

export default PRODUCT_ATTRIBUTES;
