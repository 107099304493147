import { createSelector } from 'reselect';

import { pluralize } from 'utils/format';
import { formatServings } from 'utils/product';

import { getProductById } from 'redux/modules/entities/selectors/products';
import { getProductServing } from 'redux/modules/product-details/selectors/product-contents';

export const getEntertainingAttributes = createSelector([getProductById], product => {
  if (!product) return null;

  const { leadTime, servings } = product;

  return leadTime || servings
    ? {
        servings: servings ? formatServings(servings) : null,
        leadTime: leadTime && leadTime > 0 ? pluralize(leadTime, 'day') : null,
      }
    : null;
});

export const getProductAttributes = createSelector(
  [getProductById],
  ({ attributes } = {}) => attributes,
);

export const getProductServingAttributes = createSelector(
  [getProductAttributes, getProductServing],
  (attributes, serving) =>
    attributes || serving
      ? {
          ...attributes,
          ...serving,
        }
      : null,
);
