import { connect } from 'react-redux';

import {
  getProductDisplayPrice,
  getProductFormattedPriceRange,
} from 'redux/modules/product-details/selectors/product-pricing';
import { getIsRedPriceProduct } from 'redux/modules/entities/selectors/promotions';

import ProductPrice from './ProductPrice';

export default connect((state, { productId }) => {
  return {
    displayPrice: getProductDisplayPrice(state, productId),
    formattedPriceRange: getProductFormattedPriceRange(state, productId),
    redOffer: getIsRedPriceProduct(state, productId),
  };
})(ProductPrice);
