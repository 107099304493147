import { useState, useCallback, useEffect } from 'react';
import { weightOptions } from 'constants/weightOptions';
import { redirectToLogin } from 'utils/login';
import { trackPreLoginInteraction } from 'analytics/preLoginInteractionsTracking';
import { useSelector, useDispatch } from 'react-redux';
import optimisticallyUpdateTrolleyItem from 'redux/modules/trolley/actions/optimistically-update-trolley-item';
import { isUserLoggedIn as isUserLoggedInSelector } from 'redux/modules/sessions/selectors';
import { useMakeSelector } from 'hooks/use-make-selector';

import { makeGetQuantityByLineNumber } from 'redux/modules/trolley/selectors/get-item-quantity';
import {
  makeGetOptimisticCanSubstituteByItemId,
  makeGetItemIdByLineNumber,
  makeGetShopperNotesAndCanSubstituteByLineNumber,
} from 'redux/modules/trolley/selectors/get-optimistic-item';
import { makeGetProductDefaultUom } from 'redux/modules/product-details/selectors/product-pricing';
import { saveItemToAddToTrolleyPostAuthentication } from 'utils/trolley';

const useTrolleyActions = ({ lineNumber, productId } = {}) => {
  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  const defaultUom = useMakeSelector(makeGetProductDefaultUom, productId);
  const { amount, uom } = useMakeSelector(makeGetQuantityByLineNumber, lineNumber);

  const isProductInTrolley = amount > 0;
  const optimisticTrolleyQuantityAmount = isProductInTrolley ? amount : 0;
  const optimisticTrolleyQuantityUom = isProductInTrolley ? uom : defaultUom;

  const { noteToShopper, canSubstitute: optimisticCanSubstitute } =
    useMakeSelector(makeGetShopperNotesAndCanSubstituteByLineNumber, lineNumber) ?? {};

  const trolleyItemId = useMakeSelector(makeGetItemIdByLineNumber, lineNumber);

  const blendedCanSubstitute = useMakeSelector(
    makeGetOptimisticCanSubstituteByItemId,
    trolleyItemId,
  );
  const canSubstitute = optimisticCanSubstitute ?? blendedCanSubstitute;

  const [desiredQuantity, setDesiredQuantity] = useState({
    amount: optimisticTrolleyQuantityAmount || weightOptions[optimisticTrolleyQuantityUom].default,
    uom: optimisticTrolleyQuantityUom,
  });

  const [isEditingTrolleyAmount, setEditingTrolleyAmount] = useState(false);

  useEffect(() => {
    if (!isEditingTrolleyAmount) {
      const desiredAmount = isProductInTrolley
        ? optimisticTrolleyQuantityAmount
        : weightOptions[optimisticTrolleyQuantityUom].default;

      setDesiredQuantity({
        amount: desiredAmount,
        uom: optimisticTrolleyQuantityUom,
      });
    }
  }, [
    isProductInTrolley,
    isEditingTrolleyAmount,
    optimisticTrolleyQuantityAmount,
    optimisticTrolleyQuantityUom,
  ]);

  const selectDesiredUom = useCallback(
    desiredUom => {
      const isSameUomAsTrolley = optimisticTrolleyQuantityUom === desiredUom;
      const desiredAmount =
        isSameUomAsTrolley && optimisticTrolleyQuantityAmount
          ? optimisticTrolleyQuantityAmount
          : weightOptions[desiredUom].default;

      setDesiredQuantity({
        amount: desiredAmount,
        uom: desiredUom,
      });
    },
    [optimisticTrolleyQuantityAmount, optimisticTrolleyQuantityUom],
  );

  const updateDesiredAmount = useCallback(
    desiredAmount => {
      setDesiredQuantity({
        ...desiredQuantity,
        amount: desiredAmount,
      });
    },
    [setDesiredQuantity, desiredQuantity],
  );

  const addToTrolley = useCallback(
    addOptions => {
      if (!isUserLoggedIn) {
        saveItemToAddToTrolleyPostAuthentication({
          ...addOptions,
          lineNumber,
          productId,
        });

        trackPreLoginInteraction('Add to Cart');
        redirectToLogin();

        return;
      }

      dispatch(
        optimisticallyUpdateTrolleyItem({
          ...addOptions,
          lineNumber,
          productId,
        }),
      );
    },
    [dispatch, productId, isUserLoggedIn, lineNumber],
  );

  const updateInTrolley = useCallback(
    updateOptions => {
      const isNewAmountZero = updateOptions.newQuantity.amount === 0;
      const isNewAmountLessThanUomMaximum =
        updateOptions.newQuantity.amount <= weightOptions[updateOptions.newQuantity.uom].maximum;
      const isNewAmountGreaterThanUomMinimum =
        updateOptions.newQuantity.amount >= weightOptions[updateOptions.newQuantity.uom].minimum;

      if (isNewAmountZero || (isNewAmountLessThanUomMaximum && isNewAmountGreaterThanUomMinimum)) {
        setDesiredQuantity(updateOptions.newQuantity);

        dispatch(
          optimisticallyUpdateTrolleyItem({
            quantity: updateOptions.newQuantity,
            clickContext: updateOptions.clickContext,
            lineNumber,
            productId,
            canSubstitute,
            noteToShopper,
            productPosition: updateOptions.productPosition,
            searchType: updateOptions.searchType,
          }),
        );
      }
    },
    [dispatch, productId, lineNumber, noteToShopper, canSubstitute],
  );

  return {
    desiredAmount: desiredQuantity.amount,
    desiredUom: desiredQuantity.uom,
    selectDesiredUom,
    updateDesiredAmount,
    addToTrolley,
    updateInTrolley,
    isEditingTrolleyAmount,
    setEditingTrolleyAmount,
    optimisticTrolleyQuantityUom,
    optimisticTrolleyQuantityAmount,
  };
};

export default useTrolleyActions;
