import React from 'react';
import PropTypes from 'prop-types';

import { formatProductSize } from 'utils/product';

import styles from 'components/Product/Size/ProductSize.scss';

const ProductSize = ({
  additionalClasses,
  currentSaleUnitPriceUom,
  defaultQuantityUom,
  formattedWeightRange,
  size,
  typicalWeightAmount,
  typicalWeightUom,
}) => {
  const sizeMessage =
    formattedWeightRange ||
    formatProductSize({
      currentUnit: currentSaleUnitPriceUom,
      defaultUnit: defaultQuantityUom,
      quantity: typicalWeightAmount,
      size,
      typicalUnit: typicalWeightUom,
    });

  return (
    <span className={`${styles.size} ${additionalClasses}`} data-testid="product-size">
      {sizeMessage}
    </span>
  );
};

ProductSize.displayName = 'ProductSize';

ProductSize.defaultProps = {
  additionalClasses: '',
  currentSaleUnitPriceUom: undefined,
  defaultQuantityUom: undefined,
  formattedWeightRange: '',
  size: undefined,
  typicalWeightAmount: undefined,
  typicalWeightUom: undefined,
};

ProductSize.propTypes = {
  additionalClasses: PropTypes.string,
  currentSaleUnitPriceUom: PropTypes.string,
  defaultQuantityUom: PropTypes.string,
  formattedWeightRange: PropTypes.string,
  size: PropTypes.string,
  typicalWeightAmount: PropTypes.number,
  typicalWeightUom: PropTypes.string,
};

export default ProductSize;
