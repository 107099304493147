import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getProductById } from 'redux/modules/entities/selectors/products';
import isEqual from 'lodash/isEqual';

import classNames from 'classnames';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { useExperiments } from 'components/Experiment/useExperiments';
import { SHOW_PRODUCT_TAGS } from 'constants/monetateExperiments';
import { mapDataToTags } from 'components/ProductPod2/utils';
import { Chip, ChipProps } from '@johnlewispartnership/wtr-ingredients/ingredients/Chips';

import styles from './index.scss';

export type ProductTagsProps = {
  minimumLifeToCustomer?: number;
  productId: string;
  productTagsOverride?: string[];
  showDetails?: boolean;
};

const ProductTags = ({
  minimumLifeToCustomer,
  productId,
  productTagsOverride,
  showDetails = false,
}: ProductTagsProps) => {
  // eslint-disable-next-line
  // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
  const productTags = useSelector(state => getProductById(state, productId)?.productTags, isEqual);
  const { getDecisionById } = useExperiments();

  const showTags =
    getDecisionById(SHOW_PRODUCT_TAGS.experiment).variant === SHOW_PRODUCT_TAGS.variant.showTags;

  if (
    (!productTagsOverride || productTagsOverride?.length === 0) &&
    (!productTags || productTags?.length === 0 || showTags === false)
  )
    return null;
  const curatedTags = showDetails ? productTags : productTags?.slice(0, 2);
  const showLong =
    showDetails &&
    minimumLifeToCustomer &&
    !!curatedTags.find((tag: { name: string }) => tag.name.includes('d+'));

  const longName = showLong ? (
    <Typography noMargins styleAs="paragraph" element="span">
      *Product life of{' '}
      <b>{`at least ${minimumLifeToCustomer} day${minimumLifeToCustomer > 1 ? `s` : ''}`}</b>,
      excluding day of delivery
    </Typography>
  ) : null;

  const mappedTags = mapDataToTags({ productTags: productTagsOverride || curatedTags });

  return (
    <div data-testid="productTags" className={styles.productTags}>
      <div className={classNames(styles.tagsWrapper, { [styles.withDetails]: showDetails })}>
        {mappedTags.map(
          (tag: { name: string; type?: ChipProps['colour']; icon?: ReactElement }) => {
            return (
              <div
                data-testid={tag.name}
                key={tag.name}
                className={classNames({ [styles.hasLongText]: showLong })}
              >
                <Chip
                  small
                  productChip
                  colour={tag.type || 'grey'}
                  leadingIcon={tag.icon || undefined}
                >
                  {tag.name}
                </Chip>
              </div>
            );
          },
        )}
      </div>
      {longName}
    </div>
  );
};

export default memo(ProductTags);
