import { createSelector, lruMemoize } from 'reselect';

import { weightOptions, C62 } from 'constants/weightOptions';
import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

import { getBlendedOptimisticTrolleyItems } from './get-optimistic-items';

const getQuantity = items => {
  let amount = 0;
  let firstItemQuantity;
  let uom = C62;

  amount = items.reduce((sum, item) => {
    if (!firstItemQuantity) firstItemQuantity = item.quantity;
    if (item.quantity.uom === firstItemQuantity.uom) {
      return sum + item.quantity.amount;
    }
    return sum;
  }, 0);

  if (amount > 0) {
    const uomProperties = weightOptions[firstItemQuantity.uom];
    uom = uomProperties.unit;
  }

  return { amount, uom };
};

export const getQuantityByItemId = createSelector(
  [getBlendedOptimisticTrolleyItems, (state, props) => props],
  (trolleyItems, props) => {
    const filteredItems = trolleyItems.filter(item => item.trolleyItemId === props.trolleyItemId);
    return getQuantity(filteredItems);
  },
);

export const getQuantityByProductId = createSelector(
  [getBlendedOptimisticTrolleyItems, (state, props) => props],
  (trolleyItems, props) => {
    const filteredItems = trolleyItems.filter(item => item && item.productId === props.productId);
    return getQuantity(filteredItems);
  },
);

const isQuantityEqual = (a, b) => a.amount === b.amount && a.uom === b.uom;

export const makeGetQuantityByLineNumber = () =>
  createSelector(
    [getBlendedOptimisticTrolleyItems, (_state, id) => id],
    (trolleyItems, id) => {
      const filteredItems = trolleyItems.filter(
        ({ lineNumber } = {}) =>
          lineNumber && (lineNumber === id || lineNumber === extractLineNumberFromSku(id)),
      );

      return getQuantity(filteredItems);
    },
    {
      memoize: lruMemoize,
      memoizeOptions: {
        resultEqualityCheck: isQuantityEqual,
      },
    },
  );

export const getQuantityByLineNumber = makeGetQuantityByLineNumber();

export const makeGetQuantityByItemIdOrByLineNumber = () =>
  createSelector(
    [getBlendedOptimisticTrolleyItems, (state, props) => props],
    (trolleyItems, props) => {
      let filteredItems;
      if (props.trolleyItemId) {
        filteredItems = trolleyItems.filter(item => item.trolleyItemId === props.trolleyItemId);
      } else {
        filteredItems = trolleyItems.filter(item => item.lineNumber === props.lineNumber);
      }

      return getQuantity(filteredItems);
    },
  );

export const getQuantityByItemIdOrByLineNumber = makeGetQuantityByItemIdOrByLineNumber();
