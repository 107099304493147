import { createSelector } from 'reselect';
import get from 'lodash/get';

import servingInstructions from 'constants/servingInstructions';

import { getProductById } from 'redux/modules/entities/selectors/products';

export const getProductContents = createSelector([getProductById], product =>
  get(product, 'contents', null),
);

export const getProductCookingInstructions = createSelector([getProductContents], contents =>
  get(contents, 'cookingInstructions', null),
);

export const getProductCookingStatus = createSelector([getProductContents], contents =>
  get(contents, 'cookingStatus', null),
);

export const getProductOrigins = createSelector([getProductContents], contents =>
  get(contents, 'origins', []),
);

export const getProductServing = createSelector([getProductCookingStatus], status =>
  status && servingInstructions[status] ? { [servingInstructions[status]]: true } : null,
);

export const getProductStatements = createSelector([getProductContents], product =>
  get(product, 'statements', null),
);

export const getProductUsage = createSelector([getProductContents], contents =>
  get(contents, 'brandedUsageInstr', null),
);
