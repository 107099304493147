export const setClickContext = (clickContext, e) => {
  // React uses synthetic events that may be reused
  // and we don't want to reuse clickContext
  // nativeEvent is ephemeral
  e.nativeEvent.clickContext = clickContext;
};

export function getClickContext(e) {
  return e?.nativeEvent?.clickContext;
}
