import { EventType, metadataAdImpressions, postAdTechEvent } from 'analytics/gtmServerFunctions';
import { impressionsService } from 'analytics/product-impressions-tracking';
import { usePodObserver } from './use-pod-observer';

export function usePodImpression(productData: any, sponsored?: boolean) {
  return usePodObserver((elem: HTMLElement) => {
    const { sponsorshipId, metadata, trackingUrls } = productData;
    postAdTechEvent({
      eventType: EventType.IMPRESSION,
      trackingUrls,
      sponsorshipId,
    });
    if (metadata) metadataAdImpressions(metadata);
    impressionsService.addProductImpression(elem, sponsored, sponsorshipId);
  });
}
